import { template as template_9558d97dd5234070b0be907dac551ae0 } from "@ember/template-compiler";
const FKLabel = template_9558d97dd5234070b0be907dac551ae0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
